$primary-color: #915ac2;

:root {
  --background-color-primary: white;
  --background-color-secondary: #f3f4f5;
  --background-start-primary: white;
  --background-start-secondary: #f3f4f5;
  --text-primary: #212121;
  --text-secondary: #757575;
  --text-tertiary: #484848;
  --text-disabled: #bdbdbd;
  --textfield-border: #212121;
  --textfield-border-focused: #3f51b5;
  --medium-gray: #e0e0e0;
  --hover-color: #eeeeee;
  --navbar-color: #060019;
  --background-document: white;
  --highlighted-text-background: #d6ebf2;
  --tab-color: #b6acd6;
  --panel-navigaton: lightGray;
  --button-color: #060019;
  --popup-background: 'white';
  --primary: #915ac2;
  --secondary: #060019;
}

[data-theme='dark-pl'] {
  --background-color-primary: #060018;
  --background-color-secondary: #121212;
  --background-start-primary: #060018;
  --background-start-secondary: #424242;
  --text-primary: #ccc;
  --text-secondary: #ccc;
  --text-tertiary: #ccc;
  --text-disabled: #686868;
  --textfield-border: #ccc;
  --textfield-border-focused: #ddd;
  --medium-gray: #404040;
  --hover-color: #353535;
  --navbar-color: #353535;
  --background-document: #424242;
  --highlighted-text-background: #095169;
  --tab-color: #6b55b3;
  --panel-navigaton: #222222;
  --button-color: #6b55b3;
  --popup-background: #353535;
  --primary: #915ac2;
  --secondary: #060019;
}

[data-theme='light-fr'] {
  --background-color-primary: white;
  --background-color-secondary: #f3f4f5;
  --background-start-primary: white;
  --background-start-secondary: #f3f4f5;
  --text-primary: #212121;
  --text-secondary: #757575;
  --text-tertiary: #484848;
  --text-disabled: #bdbdbd;
  --textfield-border: #212121;
  --textfield-border-focused: #3f51b5;
  --medium-gray: #e0e0e0;
  --hover-color: #eeeeee;
  --navbar-color: #060019;
  --background-document: white;
  --highlighted-text-background: #d6ebf2;
  --tab-color: #b6acd6;
  --panel-navigaton: lightGray;
  --button-color: #005b2f;
  --popup-background: 'white';
  --primary: #005b2f;
  --secondary: #38b6ff;
}

[data-theme='dark-fr'] {
  --background-color-primary: #060018;
  --background-color-secondary: #121212;
  --background-start-primary: #060018;
  --background-start-secondary: #424242;
  --text-primary: #ccc;
  --text-secondary: #ccc;
  --text-tertiary: #ccc;
  --text-disabled: #686868;
  --textfield-border: #ccc;
  --textfield-border-focused: #ddd;
  --medium-gray: #404040;
  --hover-color: #353535;
  --navbar-color: #353535;
  --background-document: #424242;
  --highlighted-text-background: #095169;
  --tab-color: #6b55b3;
  --panel-navigaton: #222222;
  --button-color: #005b2f;
  --popup-background: #353535;
  --primary: #005b2f;
  --secondary: #38b6ff;
}
