@import 'styles/_variables.scss';

.infoContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  .infoTextContainer {
    padding: 16px;
    border: 2px solid var(--primary);
    border-radius: 10px;
  }
}
