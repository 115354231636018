.policyDiv{
    margin: 150px;
}

.titleH4{
    margin-top: 20px; 
}

.statuteContainer {
    height: 80vh;
    overflow-y: auto;

    ol {
        list-style-type: decimal;
        padding-left: 20px;
        font-size: 14px;
    }

    li {
        font-size: 14px;
        margin-top: 10px;
    }
}