@import 'styles/_variables.scss';

.rightSidePanel {
  background-color: var(--background-color-secondary);
  height: 100%;
}

.leftSidePanel {
  height: 100%;
}
