@import 'styles/_variables.scss';

.documentItem {
  cursor: pointer;
  border: 1px solid var(--medium-gray);
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--hover-color);
  }
}

.documentItemContent {
  flex: 1;
  cursor: pointer;
  padding: 10px;
}
