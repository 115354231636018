@import 'styles/_variables.scss';

.container {
  display: flex;
}

.content {
  height: 100vh;
  width: calc(100vw - 81px);
  background-color: var(--background-color-primary);
}
