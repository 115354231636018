@import 'styles/_variables.scss';

.container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: var(--background-color-secondary);
  color: #fff;
  margin: 0 5px;
  border-radius: 5px;
}

.activeTab {
  background-color: #555;
}

.card {
  padding: 20px;
  background-color: var(--background-color-secondary);
  border-radius: 10px;
  border: 1px solid var(--medium-gray);
}

.planType {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.priceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  font-size: 28px;
  font-weight: bold;
  margin-right: 5px;
}

.perMonth {
  font-size: 14px;
}

.description {
  margin-top: 10px;
  margin-bottom: 20px;
}

.featuresList {
  text-align: left;
  list-style-type: disc;
  padding: 0;
  margin-bottom: 20px;

  li {
    margin-bottom: 10px;
    margin-left: 20px;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  color: #1e90ff;
  text-decoration: none;
  margin-top: 5px;
}

.footerText {
  font-size: 14px;
  margin-top: 20px;
}

.enterpriseLink {
  color: #1e90ff;
  text-decoration: none;
}

.buttonContainer {
  padding-bottom: 20px;
}
