.policyDiv {
  margin: 150px;
  width: 100%;
}

.titleH4 {
  margin-top: 20px;
}

.statuteContainer {
  height: 80vh;
  overflow-y: auto;
}

.statuteContainer p {
  margin-top: 10px;
}

.numberedParagraph {
  display: flex;
}

.number {
  width: 30px;
  flex-shrink: 0;
  flex-grow: 0;
}

.numberIndented {
  width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 20px;
}
