.title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.buttons {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  gap: 10px;
}
