.container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden;
    background: linear-gradient(to right, #915AC2 0%, #915AC2 20%, #7A4BAF 40%, #5A3BAA 60%, #3A2BA5 80%, blue 100%);  
}

.svgImg{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.contentParent{
    margin-top: 200px;
    display: flex;
    width: 100%;
    justify-content: space-around;
}