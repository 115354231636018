@import 'styles/_variables.scss';

.priceList {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 2px solid #38b6ff;
  border-radius: 20px;
  background-color: var(--background-color-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 400px;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconPlaceholder {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.featureContent h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.featureContent p {
  margin: 0;
  color: #38b6ff;
  font-size: 16px;
}

.tileTitle {
  //   color: var(--primary);
  font-size: 20px;
}
