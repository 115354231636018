.title {
  font-family: 'Merriweather';
  color: #38b6ff;
  font-size: 64px;
  margin-bottom: 20px;
}

.text {
  font-family: 'Merriweather';
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}
