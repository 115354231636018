.logoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    outline: none;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.logoBoxFrench {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.small {
  width: 24px;
  height: 24px;
}

.medium {
  width: 50px;
  height: 50px;
}

.large {
  width: 80px;
  height: 80px;
}

.none {
  height: 100%;
  width: 100%;
}

.border {
  border: 3px solid black;
}

.borderNone {
  border: none;
}
