.fullLogoBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logoButton {
  border: none !important;
  background: none !important;
  cursor: pointer;
  color: inherit;
}

.logoText {
  font-size: 36px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}

.logoTextColorful {
  color: var(--primary);
}

.logoTextLight {
  color: var(--background-color-primary);
}

.logoTextDark {
  color: var(--text-primary);
}

.small {
  width: 24px;
  height: 24px;
}

.medium {
  width: 50px;
  height: 50px;
}

.large {
  width: 80px;
  height: 80px;
}

.logoBoxFrench {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logoTextFrench {
  font-size: 36px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}

.logoTextFrench:nth-child(1) {
  color: var(--primary);
}

.logoTextFrench:nth-child(2) {
  color: var(--secondary);
}

.logoTextFrench:nth-child(3) {
  color: var(--primary);
}
