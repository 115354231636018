@import 'styles/_variables.scss';

.paperBox {
  padding: 20px;
  width: 420px;
  background-color: var(--background-color-secondary);
  border: 1px solid var(--textfield-border);
  border-radius: 10px;
  max-width: 100vw;
}

.mainDiv {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoBox {
  margin-bottom: 20px;
  margin-top: 12px;
}

.textTitle {
  font-size: 24px;
}

.error {
  color: firebrick;
  height: 20px;
}
