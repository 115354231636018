@import 'styles/_variables.scss';

.lawButton {
  text-transform: 'none';
  width: '100%';
  padding: 10px;
  align-content: left;
  align-items: left;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
  &:hover {
    background-color: var(--hover-color);
  }
}
