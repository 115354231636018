.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.description {
  font-style: italic;
  color: darkgray;
  width: 100%;
  text-align: center;
  white-space: pre-line;
}

.circularWrapper {
  display: flex;
  justify-content: center;
}
