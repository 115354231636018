@import 'styles/_variables.scss';

.chatPersonTitle {
  padding: 3px 8px;
  border-radius: 8px;
  background: #eeeeee;
  display: inline-block;
}

.chatBubble {
  background: var(--background-color-primary);
  border: 1px solid lightGray;
  border-radius: 10px;
  padding: 10px;
}

.responseBubble {
  margin-right: '20px';
}

.questionBubble {
  margin-left: 20px;
}
.questionBubbleHover {
  cursor: pointer;
  &:hover {
    background: var(--hover-color);
  }
}
