@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.animate-charcter {
  background-image: linear-gradient(
    -225deg,
    #4d4b52 0%,
    #44107a 29%,
    #aca8a8 67%,
    #6b65be 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

svg text {
  fill: none;
  stroke: #fff;
  font-size: 14px;
  stroke-width: 1;
  animation: textAnimate 3s infinite;
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 100%;
    stroke-dashoffset: 0;
    fill: #949494;
  }

  100% {
    stroke-dasharray: 100% 0;
    stroke-dashoffset: 0;
    fill: #a1a1a1;
  }
}
