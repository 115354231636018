@import 'styles/_variables.scss';

.mainDiv {
  width: 100vw;
  height: calc(100vh - 70px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  background-color: var(--background-start-primary);
}

.title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.articlesDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.greetingsDiv {
  width: 100%;
}

.largePadding {
  padding: 130px;
}

.smallPadding {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
