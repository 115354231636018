@import 'styles/_variables.scss';

.rightSidePanelGrid {
  position: relative;
  background-color: var(--background-color-secondary);
}

.lawContainer,
.rulingContainer {
  background: var(--background-document);
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}
