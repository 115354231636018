@import 'styles/_variables.scss';

.textContainer,
.textContainerDistinct {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.textContainer {
  max-height: 150px;
}

.textContainerDistinct {
  max-height: 250px;
}

.textContainer.expanded,
.textContainerDistinct.expanded {
  max-height: var(--expanded-height, 2000px);
}

.fadeOut {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 30px,
    var(--background-color-primary) 100%
  );
}

.textContainer.expanded .fadeOut,
.textContainerDistinct.expanded .fadeOut {
  display: none;
}
