.container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
  background: #38b6ff;
}

.svgImg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.contentParent {
  margin-top: 200px;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.title {
  font-size: 48px;
  font-family: 'Merriweather';
  color: #005b2f;
}
