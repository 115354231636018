/* .navBar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 30;
    transition: all 0.3s ease-in-out;
    margin-bottom: 16px;
}

.navBar.bgWhiteShadow {
    background: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
} */

.logoContainer {
  padding-left: 10px;

  @media screen and (min-width: 768px) {
    padding-left: 48px;
  }
}
