@import 'styles/_variables.scss';

.container {
  display: flex;
  background-color: var(--background-start-primary);
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 66px);
  width: 100vw;
}
