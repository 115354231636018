@import 'styles/_variables.scss';

.container {
  background-color: var(--background-color-secondary);
  padding: 10px;
}

.descriptionContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.title {
  font-size: 46px;
  font-weight: 700;
  margin: 30px 0;
  color: $primary-color;
}

.linkContainer {
  margin-top: 50px;
  margin-bottom: 50px;
}

.descriptionText {
  margin: 30px 0;
  max-width: 400px;
  font-size: 20px;
  color: var(--text-secondary);
}
