@import 'styles/_variables.scss';


p {
    color: var(--text-tertiary);
    line-height: 1.4em;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

h1{
    color: var(--text-tertiary);
}


h2{
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.1;
    color: var(--text-tertiary);
    box-sizing: border-box;
    border-color: #484848;
    border-style: solid;
    border-width: 0 0 1px 0;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}

h3{
    margin-bottom: 10px;
    line-height: 1.1;
    color: var(--text-tertiary);
    box-sizing: border-box;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}

h6{
    margin-bottom: 10px;
    line-height: 1.1;
    color: var(--text-tertiary);
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.05em;
}

h1, h2, h3, h4, h5, h6, div{
    font-family: 'Lato', sans-serif;
}
