.customlink {
  text-decoration: none;
  color: var(--primary);
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }
  font-size: 13px;
}
.bottomlink {
  color: var(--text-primary);
}

.verticalDivider {
  border: none;
  border-left: 2px solid rgba(0, 0, 0, 0.12);
  height: 19px;
  width: 1px;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}

.buttonLoadingBox {
  width: 100%;
  margin-top: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
}
