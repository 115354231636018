@import 'styles/_variables.scss';

.sectionTextDiv {
  margin-bottom: 20px;
  margin-top: 15px;
  text-align: center;
}

.titleText {
  text-align: center;
  font-size: 50px;
  margin-top: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.blogDiv {
  width: 100vw;
  height: calc(100vh - 70px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  background-color: var(--background-start-primary);
  font-size: 18px;

  li {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.blogArticleContainerNarrow {
  width: 70%;
}

.blogArticleContainerWide {
  width: 100%;
}

.sectionImage {
  width: 100%;
  max-height: 800px;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  object-fit: cover;
  margin-top: 50px;
}

.imageParagraph {
  margin-bottom: 50px;
}

.imageDescription {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.dateText {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.bottomLinkBox {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sourcesBox {
  margin-top: 20px;
  margin-left: 18px;
  margin-right: 18px;
}
